.icon-btn {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: var(--space-2);
  border-radius: var(--rounded-full);
  border: 0;
  background: transparent;
  color: var(--color-primary-text);
  cursor: pointer;
  transition: all 300ms ease;

  &:hover,
  &:focus-visible {
    background-color: var(--color-neutral-bgHover);
  }

  &:active {
    background-color: var(--color-neutral-bgActive);
  }
}
