.toggle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-2);
  border: 1px solid var(--color-primary-border);
  border-radius: var(--rounded-full);
  background-color: var(--color-primary-bg);
  cursor: pointer;
  color: var(--color-primary-text);
  transition: all 300ms ease;

  &:hover {
    background-color: var(--color-primary-bgHover);
  }

  &[aria-pressed="true"] {
    background-color: var(--color-primary-bgSolid);
    color: var(--color-on-primary);

    svg {
      fill: currentColor;
    }
  }
}
