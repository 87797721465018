.nav-group {
  padding-inline: var(--space-4);
  padding-block: var(--space-4);

  &:not(:first-of-type) {
    border-block-start: 1px solid var(--color-neutral-line);
  }
}

.nav-group__title {
  margin-block-end: var(--space-2);
  font-size: var(--font-size-sm);
  color: var(--color-neutral-text);
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.nav-group__subtitle {
  margin-block-end: var(--space-1);
  font-size: var(--font-size-xs);
  color: var(--color-neutral-text);
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.nav-group__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-group__link {
  display: flex;
  gap: var(--space-2);
  align-items: center;
  padding-block: var(--space-2);
  padding-inline: var(--space-2);
  border-radius: var(--rounded-md);
  color: var(--color-neutral-text);
  font-size: var(--font-size-base);
  text-decoration: none;

  &:hover {
    background-color: var(--color-neutral-bg);
  }

  &[aria-current="page"] {
    background-color: var(--color-primary-bg);
  }

  svg {
    flex-shrink: 0;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
