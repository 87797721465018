@use "./../tools/tokens.scss";
@use "./../settings/tokens-core.scss";
@use "./../settings/tokens-light.scss";
@use "./../settings/tokens-dark.scss";

:root {
  @include tokens.build(tokens-core.$tokens);
  @include tokens.build(tokens-light.$tokens);
}

body {
  &[data-theme="dark"] {
    @include tokens.build(tokens-dark.$tokens);
  }

  &[data-theme="system"] {
    @media (prefers-color-scheme: dark) {
      @include tokens.build(tokens-dark.$tokens);
    }
  }
}
