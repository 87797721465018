@use "./../tools/breakpoints.scss" as *;

.sidebar {
  inline-size: 80vi;
  max-inline-size: 500px;
  position: absolute;
  inset-block: 0;
  inset-inline-start: 0;
  z-index: 1;
  overflow: auto;
  border-inline-end: 1px solid var(--color-neutral-line);
  background-color: var(--color-background);

  &[data-open="false"] {
    display: none;
  }

  @include media-breakpoint-up("xl") {
    position: relative;
    inline-size: 100%;
    block-size: 100%;
    inset: unset;

    &[data-open="false"] {
      display: block;
    }
  }
}
