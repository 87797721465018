code[class*="language-"], pre[class*="language-"] {
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  color: #90a4ae;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
  background: #fafafa;
  font-family: Roboto Mono, monospace;
  font-size: 1em;
  line-height: 1.5em;
}

code[class*="language-"]::selection, pre[class*="language-"]::selection, code[class*="language-"] ::selection, pre[class*="language-"] ::selection {
  color: #263238;
  background: #cceae7;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .2em;
  padding: .1em;
}

pre[class*="language-"] {
  margin: .5em 0;
  padding: 1.25em 1em;
  position: relative;
  overflow: auto;
}

.language-css > code, .language-sass > code, .language-scss > code {
  color: #f76d47;
}

[class*="language-"] .namespace {
  opacity: .7;
}

.token.atrule {
  color: #7c4dff;
}

.token.attr-name {
  color: #39adb5;
}

.token.attr-value, .token.attribute {
  color: #f6a434;
}

.token.boolean {
  color: #7c4dff;
}

.token.builtin, .token.cdata, .token.char, .token.class {
  color: #39adb5;
}

.token.class-name {
  color: #6182b8;
}

.token.comment {
  color: #aabfc9;
}

.token.constant {
  color: #7c4dff;
}

.token.deleted {
  color: #e53935;
}

.token.doctype {
  color: #aabfc9;
}

.token.entity {
  color: #e53935;
}

.token.function {
  color: #7c4dff;
}

.token.hexcode {
  color: #f76d47;
}

.token.id, .token.important {
  color: #7c4dff;
  font-weight: bold;
}

.token.inserted {
  color: #39adb5;
}

.token.keyword {
  color: #7c4dff;
}

.token.number {
  color: #f76d47;
}

.token.operator {
  color: #39adb5;
}

.token.prolog {
  color: #aabfc9;
}

.token.property {
  color: #39adb5;
}

.token.pseudo-class, .token.pseudo-element {
  color: #f6a434;
}

.token.punctuation {
  color: #39adb5;
}

.token.regex {
  color: #6182b8;
}

.token.selector {
  color: #e53935;
}

.token.string {
  color: #f6a434;
}

.token.symbol {
  color: #7c4dff;
}

.token.tag {
  color: #e53935;
}

.token.unit {
  color: #f76d47;
}

.token.url, .token.variable {
  color: #e53935;
}

:root {
  --font-family-default: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --font-family-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  --space-0: 0;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 24px;
  --space-6: 36px;
  --space-7: 48px;
  --space-8: 64px;
  --rounded-none: 0;
  --rounded-sm: 2px;
  --rounded-md: 4px;
  --rounded-lg: 8px;
  --rounded-full: 9999px;
  --font-size-xs: .75rem;
  --font-size-sm: .875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 2rem;
  --font-size-4xl: 2.5rem;
  --font-size-fluid--2: clamp(.78rem, .77rem + .03vw, .8rem);
  --font-size-fluid--1: clamp(.94rem, .92rem + .11vw, 1rem);
  --font-size-fluid-0: clamp(1.13rem, 1.08rem + .22vw, 1.25rem);
  --font-size-fluid-1: clamp(1.35rem, 1.28rem + .37vw, 1.56rem);
  --font-size-fluid-2: clamp(1.62rem, 1.5rem + .58vw, 1.95rem);
  --font-size-fluid-3: clamp(1.94rem, 1.77rem + .87vw, 2.44rem);
  --font-size-fluid-4: clamp(2.33rem, 2.08rem + 1.25vw, 3.05rem);
  --font-size-fluid-5: clamp(2.8rem, 2.45rem + 1.77vw, 3.82rem);
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --color-background: #fff;
  --color-surface: #fff;
  --color-neutral-1: #fbfcfd;
  --color-neutral-2: #f8f9fa;
  --color-neutral-3: #f1f3f5;
  --color-neutral-4: #eceef0;
  --color-neutral-5: #e6e8eb;
  --color-neutral-6: #dfe3e6;
  --color-neutral-7: #d7dbdf;
  --color-neutral-8: #c1c8cd;
  --color-neutral-9: #889096;
  --color-neutral-10: #7e868c;
  --color-neutral-11: #687076;
  --color-neutral-12: #11181c;
  --color-neutral-base: #fbfcfd;
  --color-neutral-bgSubtle: #f8f9fa;
  --color-neutral-bg: #f1f3f5;
  --color-neutral-bgHover: #eceef0;
  --color-neutral-bgActive: #e6e8eb;
  --color-neutral-line: #dfe3e6;
  --color-neutral-border: #d7dbdf;
  --color-neutral-borderHover: #c1c8cd;
  --color-neutral-bgSolid: #889096;
  --color-neutral-bgSolidHover: #7e868c;
  --color-neutral-text: #687076;
  --color-neutral-textContrast: #11181c;
  --color-neutral-placeholder: #889096;
  --color-primary-1: #f9feff;
  --color-primary-2: #f1fcff;
  --color-primary-3: #e4f9ff;
  --color-primary-4: #d5f4fd;
  --color-primary-5: #c1ecf9;
  --color-primary-6: #a4dff1;
  --color-primary-7: #79cfea;
  --color-primary-8: #2ebde5;
  --color-primary-9: #68ddfd;
  --color-primary-10: #5fd4f4;
  --color-primary-11: #0078a1;
  --color-primary-12: #003242;
  --color-primary-base: #f9feff;
  --color-primary-bgSubtle: #f1fcff;
  --color-primary-bg: #e4f9ff;
  --color-primary-bgHover: #d5f4fd;
  --color-primary-bgActive: #c1ecf9;
  --color-primary-line: #a4dff1;
  --color-primary-border: #79cfea;
  --color-primary-borderHover: #2ebde5;
  --color-primary-bgSolid: #68ddfd;
  --color-primary-bgSolidHover: #5fd4f4;
  --color-primary-text: #0078a1;
  --color-primary-textContrast: #003242;
  --color-primary-placeholder: #68ddfd;
  --color-on-primary: #000;
  --shadow-xs: 0 1px 3px #0c0c0c17;
  --shadow-sm: 0 1px 5px #0c0c0c0d;
  --shadow-md: 0 0 0 1px var(--color-neutral-border), 0 1px 5px #0c0c0c0d, 0 0 40px #0c0c0c04;
  --shadow-lg: 0 0 0 1px var(--color-neutral-border), 0 5px 17px #0c0c0c24;
  --shadow-xl: 0 4px 12px #0c0c0c26, 0 0 0 1px #0000000d;
  --shadow-xxl: 0 24px 38px 3px #0c0c0c29, 0 9px 86px 8px #0c0c0c1a, 0 11px 15px -7px #0c0c0c1a, 0 0 0 1px #0000000d;
}

body[data-theme="dark"] {
  --color-background: #151718;
  --color-surface: #151718;
  --color-neutral-1: #151718;
  --color-neutral-2: #1a1d1e;
  --color-neutral-3: #202425;
  --color-neutral-4: #26292b;
  --color-neutral-5: #2b2f31;
  --color-neutral-6: #313538;
  --color-neutral-7: #3a3f42;
  --color-neutral-8: #4c5155;
  --color-neutral-9: #697177;
  --color-neutral-10: #787f85;
  --color-neutral-11: #9ba1a6;
  --color-neutral-12: #ecedee;
  --color-neutral-base: #151718;
  --color-neutral-bgSubtle: #1a1d1e;
  --color-neutral-bg: #202425;
  --color-neutral-bgHover: #26292b;
  --color-neutral-bgActive: #2b2f31;
  --color-neutral-line: #313538;
  --color-neutral-border: #3a3f42;
  --color-neutral-borderHover: #4c5155;
  --color-neutral-bgSolid: #697177;
  --color-neutral-bgSolidHover: #787f85;
  --color-neutral-text: #9ba1a6;
  --color-neutral-textContrast: #ecedee;
  --color-neutral-placeholder: #697177;
  --color-primary-1: #0c1820;
  --color-primary-2: #071d2a;
  --color-primary-3: #082636;
  --color-primary-4: #082d41;
  --color-primary-5: #08354c;
  --color-primary-6: #083e59;
  --color-primary-7: #064b6b;
  --color-primary-8: #005d85;
  --color-primary-9: #68ddfd;
  --color-primary-10: #8ae8ff;
  --color-primary-11: #2ec8ee;
  --color-primary-12: #eaf8ff;
  --color-primary-base: #0c1820;
  --color-primary-bgSubtle: #071d2a;
  --color-primary-bg: #082636;
  --color-primary-bgHover: #082d41;
  --color-primary-bgActive: #08354c;
  --color-primary-line: #083e59;
  --color-primary-border: #064b6b;
  --color-primary-borderHover: #005d85;
  --color-primary-bgSolid: #68ddfd;
  --color-primary-bgSolidHover: #8ae8ff;
  --color-primary-text: #2ec8ee;
  --color-primary-textContrast: #eaf8ff;
  --color-primary-placeholder: #68ddfd;
  --color-on-primary: #000;
  --shadow-xs: 0 1px 3px #64646417;
  --shadow-sm: 0 1px 5px #6464640d;
  --shadow-md: 0 0 0 1px var(--color-neutral-border), 0 1px 5px #6464640d, 0 0 40px #64646404;
  --shadow-lg: 0 0 0 1px var(--color-neutral-border), 0 5px 17px #64646424;
  --shadow-xl: 0 4px 12px #64646426, 0 0 0 1px #0000000d;
  --shadow-xxl: 0 24px 38px 3px #64646429, 0 9px 86px 8px #6464641a, 0 11px 15px -7px #6464641a, 0 0 0 1px #0000000d;
}

@media (prefers-color-scheme: dark) {
  body[data-theme="system"] {
    --color-background: #151718;
    --color-surface: #151718;
    --color-neutral-1: #151718;
    --color-neutral-2: #1a1d1e;
    --color-neutral-3: #202425;
    --color-neutral-4: #26292b;
    --color-neutral-5: #2b2f31;
    --color-neutral-6: #313538;
    --color-neutral-7: #3a3f42;
    --color-neutral-8: #4c5155;
    --color-neutral-9: #697177;
    --color-neutral-10: #787f85;
    --color-neutral-11: #9ba1a6;
    --color-neutral-12: #ecedee;
    --color-neutral-base: #151718;
    --color-neutral-bgSubtle: #1a1d1e;
    --color-neutral-bg: #202425;
    --color-neutral-bgHover: #26292b;
    --color-neutral-bgActive: #2b2f31;
    --color-neutral-line: #313538;
    --color-neutral-border: #3a3f42;
    --color-neutral-borderHover: #4c5155;
    --color-neutral-bgSolid: #697177;
    --color-neutral-bgSolidHover: #787f85;
    --color-neutral-text: #9ba1a6;
    --color-neutral-textContrast: #ecedee;
    --color-neutral-placeholder: #697177;
    --color-primary-1: #0c1820;
    --color-primary-2: #071d2a;
    --color-primary-3: #082636;
    --color-primary-4: #082d41;
    --color-primary-5: #08354c;
    --color-primary-6: #083e59;
    --color-primary-7: #064b6b;
    --color-primary-8: #005d85;
    --color-primary-9: #68ddfd;
    --color-primary-10: #8ae8ff;
    --color-primary-11: #2ec8ee;
    --color-primary-12: #eaf8ff;
    --color-primary-base: #0c1820;
    --color-primary-bgSubtle: #071d2a;
    --color-primary-bg: #082636;
    --color-primary-bgHover: #082d41;
    --color-primary-bgActive: #08354c;
    --color-primary-line: #083e59;
    --color-primary-border: #064b6b;
    --color-primary-borderHover: #005d85;
    --color-primary-bgSolid: #68ddfd;
    --color-primary-bgSolidHover: #8ae8ff;
    --color-primary-text: #2ec8ee;
    --color-primary-textContrast: #eaf8ff;
    --color-primary-placeholder: #68ddfd;
    --color-on-primary: #000;
    --shadow-xs: 0 1px 3px #64646417;
    --shadow-sm: 0 1px 5px #6464640d;
    --shadow-md: 0 0 0 1px var(--color-neutral-border), 0 1px 5px #6464640d, 0 0 40px #64646404;
    --shadow-lg: 0 0 0 1px var(--color-neutral-border), 0 5px 17px #64646424;
    --shadow-xl: 0 4px 12px #64646426, 0 0 0 1px #0000000d;
    --shadow-xxl: 0 24px 38px 3px #64646429, 0 9px 86px 8px #6464641a, 0 11px 15px -7px #6464641a, 0 0 0 1px #0000000d;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

fieldset {
  border: 0;
  padding: 0;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

ul[role="list"] {
  margin: 0;
  padding: 0;
  list-style: none;
}

body {
  font-family: var(--font-family-default);
  color: var(--color-neutral-textContrast);
  background-color: var(--color-background);
}

.app-header {
  align-items: center;
  gap: var(--space-4);
  padding-block: var(--space-2);
  padding-inline: var(--space-4);
  border-block-end: 1px solid var(--color-neutral-line);
  background-color: var(--color-surface);
  align-items: center;
  display: flex;
}

.app-header > * {
  flex-shrink: 0;
}

.app-header__title {
  color: var(--color-neutral-textContrast);
  font-weight: var(--font-weight-bold);
  flex-shrink: 1;
  margin-inline-end: var(--space-6);
  text-decoration: none;
}

.app-layout {
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  display: grid;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.app-layout__wrapper {
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: auto minmax(0, 1fr);
  display: grid;
  overflow: hidden;
}

.app-layout__sidebar {
  block-size: 100%;
  position: relative;
}

@media (min-width: 75em) {
  .app-layout__sidebar {
    inline-size: 300px;
    overflow: auto;
  }
}

.app-layout__main {
  overflow: auto;
}

.article__content {
  max-inline-size: 70ch;
  margin-inline-end: auto;
  margin-block: var(--space-6);
}

.article__title {
  font-size: var(--font-size-4xl);
  margin: 0;
  margin-block-end: var(--space-6);
}

.article__content p {
  margin-block-end: var(--space-4);
  line-height: 1.45;
}

.article__content p:last-child {
  margin-block-end: 0;
}

.article__content ul, .article__content ol {
  margin-block: var(--space-4);
  padding-inline-start: var(--space-4);
}

.article__content li > ul, .article__content li > ol {
  margin-block: var(--space-1);
}

.article__content h1 {
  font-size: var(--font-size-2xl);
  margin-block-start: var(--space-6);
  margin-block-end: var(--space-1);
}

.article__content h2 {
  font-size: var(--font-size-xl);
  margin-block-start: var(--space-6);
  margin-block-end: var(--space-1);
}

.article__content h1 + h2, .article__content h2 + h3, .article__content h3 + h4, .article__content h4 + h5, .article__content h5 + h6 {
  margin-block-start: var(--space-1);
}

.article__content h1, .article__content h2, .article__content h3, .article__content h4, .article__content h5, .article__content h6 {
  scroll-margin-block-start: 70px;
}

@media (min-width: 50em) {
  .article__content h1, .article__content h2, .article__content h3, .article__content h4, .article__content h5, .article__content h6 {
    scroll-margin-block-start: 20px;
  }
}

.article__content a {
  color: var(--color-primary-text);
}

.article__content table {
  inline-size: 100%;
  margin-block: var(--space-5);
  border-block-start: 1px solid var(--color-neutral-border);
  border-collapse: collapse;
}

.article__content table td, .article__content table th {
  border-bottom: 1px solid var(--color-neutral-border);
  padding-inline: var(--space-1);
}

.article__content table td {
  padding-block: var(--space-3);
}

.article__content table th {
  padding-block: var(--space-2);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  letter-spacing: .01em;
}

.article__content blockquote {
  border-inline-start: 3px solid var(--color-neutral-line);
  padding-inline-start: var(--space-4);
}

.article__content blockquote p {
  color: var(--color-neutral-text);
  font-style: italic;
}

.article__content pre, .article__content code {
  font-family: var(--font-family-mono);
  line-height: 1.5;
}

.article__content pre {
  direction: ltr;
  text-align: start;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  tab-size: 2;
  -webkit-hyphens: none;
  hyphens: none;
  border-radius: var(--rounded-lg);
  background-color: var(--color-neutral-bgSubtle);
  margin: 0;
  padding: 1em;
  font-size: 14px;
  line-height: 1.375;
  overflow: auto;
  margin-block-end: var(--space-4) !important;
}

.article__content code {
  border-radius: var(--rounded-md);
  background-color: var(--color-neutral-bg);
  padding: .2em .3em;
  font-size: .9em;
}

.article__content pre code {
  font-size: inherit;
  word-break: break-all;
  padding: 0;
  background-color: #0000 !important;
}

.article__content .footnotes-sep {
  margin-block: var(--space-6);
  border-style: solid;
  border-color: var(--color-neutral-line);
}

.article__content .direct-link {
  outline-offset: 4px;
  color: #0000;
  margin-inline-start: .1em;
  text-decoration: none;
  transition: color .3s;
}

.article__content :hover > .direct-link, .article__content .direct-link:focus {
  color: var(--color-neutral-text);
}

.grouped-links__label {
  color: var(--color-neutral-text);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  margin: 0;
  margin-block-end: var(--space-2);
}

.grouped-links__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.grouped-links__link {
  max-inline-size: 100%;
  padding-block: var(--space-1);
  color: var(--color-neutral-text);
  font-size: var(--font-size-sm);
  align-items: center;
  text-decoration: none;
  display: inline-flex;
}

.grouped-links__link svg {
  inline-size: 16px;
  block-size: 16px;
  box-sizing: content-box;
  flex-shrink: 0;
  padding-inline-end: var(--space-2);
}

.grouped-links__link span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.grouped-links__link:hover span {
  text-decoration: underline;
}

.icon-btn {
  padding: var(--space-2);
  border-radius: var(--rounded-full);
  color: var(--color-primary-text);
  cursor: pointer;
  background: none;
  border: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: 0;
  transition: all .3s;
  display: flex;
}

.icon-btn:hover, .icon-btn:focus-visible {
  background-color: var(--color-neutral-bgHover);
}

.icon-btn:active {
  background-color: var(--color-neutral-bgActive);
}

.nav-group {
  padding-inline: var(--space-4);
  padding-block: var(--space-4);
}

.nav-group:not(:first-of-type) {
  border-block-start: 1px solid var(--color-neutral-line);
}

.nav-group__title {
  font-size: var(--font-size-sm);
  color: var(--color-neutral-text);
  text-transform: uppercase;
  letter-spacing: .04em;
  margin-block-end: var(--space-2);
}

.nav-group__subtitle {
  font-size: var(--font-size-xs);
  color: var(--color-neutral-text);
  text-transform: uppercase;
  letter-spacing: .02em;
  margin-block-end: var(--space-1);
}

.nav-group__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-group__link {
  gap: var(--space-2);
  padding-block: var(--space-2);
  padding-inline: var(--space-2);
  border-radius: var(--rounded-md);
  color: var(--color-neutral-text);
  font-size: var(--font-size-base);
  align-items: center;
  text-decoration: none;
  display: flex;
}

.nav-group__link:hover {
  background-color: var(--color-neutral-bg);
}

.nav-group__link[aria-current="page"] {
  background-color: var(--color-primary-bg);
}

.nav-group__link svg {
  flex-shrink: 0;
}

.nav-group__link span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.note-link {
  color: var(--color-neutral-text);
  font-size: var(--font-size-lg);
  text-decoration: none;
}

.note-link:hover {
  color: var(--color-primary-text);
  text-decoration: underline;
}

.page {
  padding-block: var(--space-4);
  padding-inline: var(--space-4);
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (min-width: 50em) {
  .page {
    padding-block: var(--space-6);
  }
}

.page__wrapper {
  gap: var(--space-8);
  grid-template-columns: minmax(0, 70ch);
  display: grid;
}

@media (min-width: 64em) {
  .page__wrapper {
    grid-template-columns: minmax(0, 70ch) 360px;
  }
}

.page__title {
  font-size: var(--font-size-fluid-3);
  margin: 0;
  margin-block-end: var(--space-6);
  line-height: 1.3;
}

.search {
  position: relative;
}

.search__input {
  inline-size: 230px;
  padding-block: var(--space-2);
  padding-inline: var(--space-5);
  border-radius: var(--rounded-full);
  background-color: var(--color-neutral-bg);
  color: var(--color-neutral-textContrast);
  border: none;
}

.search[data-mode="inline"] .search__input {
  inline-size: 100%;
  padding-inline: var(--space-4);
  border-radius: var(--rounded-md);
  display: block;
}

.search__input:hover {
  background-color: var(--color-neutral-bgHover);
}

.search__input::placeholder {
  color: var(--color-neutral-placeholder);
}

.search[data-mode="overlay"] .search__popover {
  inline-size: 400px;
  max-block-size: 300px;
  z-index: 1;
  padding-block: var(--space-1);
  padding-inline: var(--space-1);
  box-shadow: var(--shadow-lg);
  border-radius: var(--rounded-sm);
  background-color: var(--color-surface);
  position: absolute;
  inset-block-start: calc(100% + 4px);
  inset-inline-end: 0;
  overflow: auto;
}

.search[data-mode="inline"] .search__popover {
  margin-block-start: var(--space-6);
}

.search__info {
  color: var(--color-neutral-text);
}

.search[data-mode="overlay"] .search__info {
  padding-inline: var(--space-4);
  padding-block: var(--space-2);
  font-size: var(--font-size-sm);
}

.search__results {
  margin: 0;
  padding: 0;
  list-style: none;
}

.search__results > li + li {
  margin-block-start: var(--space-1);
}

.search__result {
  gap: var(--space-1);
  padding-inline: var(--space-4);
  padding-block: var(--space-2);
  border-radius: var(--rounded-sm);
  flex-direction: column;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.search__result[data-selected="true"], .search__result:hover {
  background-color: var(--color-primary-bgSubtle);
}

.search__note-title {
  color: var(--color-primary-text);
}

.search__note-content {
  color: var(--color-neutral-text);
  font-size: var(--font-size-sm);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sidebar {
  inline-size: 80vi;
  max-inline-size: 500px;
  z-index: 1;
  border-inline-end: 1px solid var(--color-neutral-line);
  background-color: var(--color-background);
  position: absolute;
  inset-block: 0;
  inset-inline-start: 0;
  overflow: auto;
}

.sidebar[data-open="false"] {
  display: none;
}

@media (min-width: 75em) {
  .sidebar {
    inline-size: 100%;
    block-size: 100%;
    inset: unset;
    position: relative;
  }

  .sidebar[data-open="false"] {
    display: block;
  }
}

.skip-link {
  opacity: 0;
  padding-block: var(--space-1);
  padding-inline: var(--space-3);
  box-shadow: var(--shadow-xl);
  border-radius: var(--rounded-md);
  background-color: var(--color-primary-bgSolid);
  color: var(--color-on-primary);
  font-weight: var(--font-weight-medium);
  z-index: 1;
  text-decoration: none;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  transform: translateX(-100%);
}

.skip-link:focus {
  opacity: 1;
  inset-block-start: var(--space-3);
  inset-inline-start: var(--space-3);
  transform: translateX(0);
}

.theme-switcher {
  border-radius: var(--rounded-full);
  background-color: var(--color-neutral-bg);
  justify-content: center;
  align-items: center;
  padding: 3px;
  display: flex;
}

.theme-switcher:has(input:focus-visible) {
  background-color: var(--color-neutral-bgHover);
}

.theme-switcher__label {
  padding: var(--space-2);
  border-radius: var(--rounded-full);
  color: var(--color-neutral-text);
  cursor: pointer;
}

.theme-switcher__label:hover {
  color: var(--color-neutral-textContrast);
  background-color: var(--color-neutral-bgHover);
}

.theme-switcher__label:has(input:checked) {
  box-shadow: var(--shadow-sm);
  color: var(--color-primary-text);
  background-color: var(--color-surface);
}

.toc__label {
  color: var(--color-neutral-text);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  margin: 0;
}

.toc__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.toc__item {
  margin-block: var(--space-1);
  margin-inline-start: calc((var(--level, 1)  - 1) * var(--space-4));
}

.toc__link {
  color: var(--color-primary-text);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-normal);
  text-decoration: none;
}

.toc__link:hover {
  text-decoration: underline;
}

.toggle-btn {
  padding: var(--space-2);
  border: 1px solid var(--color-primary-border);
  border-radius: var(--rounded-full);
  background-color: var(--color-primary-bg);
  cursor: pointer;
  color: var(--color-primary-text);
  justify-content: center;
  align-items: center;
  transition: all .3s;
  display: flex;
}

.toggle-btn:hover {
  background-color: var(--color-primary-bgHover);
}

.toggle-btn[aria-pressed="true"] {
  background-color: var(--color-primary-bgSolid);
  color: var(--color-on-primary);
}

.toggle-btn[aria-pressed="true"] svg {
  fill: currentColor;
}

[x-cloak] {
  display: none !important;
}

@media (min-width: 0) {
  .hide-xs {
    display: none !important;
  }
}

@media (min-width: 37em) {
  .hide-sm {
    display: none !important;
  }
}

@media (min-width: 50em) {
  .hide-md {
    display: none !important;
  }
}

@media (min-width: 64em) {
  .hide-lg {
    display: none !important;
  }
}

@media (min-width: 75em) {
  .hide-xl {
    display: none !important;
  }
}

@media (min-width: 90em) {
  .hide-2xl {
    display: none !important;
  }
}

.show-xs {
  display: none !important;
}

@media (min-width: 0) {
  .show-xs {
    display: block !important;
  }
}

.show-sm {
  display: none !important;
}

@media (min-width: 37em) {
  .show-sm {
    display: block !important;
  }
}

.show-md {
  display: none !important;
}

@media (min-width: 50em) {
  .show-md {
    display: block !important;
  }
}

.show-lg {
  display: none !important;
}

@media (min-width: 64em) {
  .show-lg {
    display: block !important;
  }
}

.show-xl {
  display: none !important;
}

@media (min-width: 75em) {
  .show-xl {
    display: block !important;
  }
}

.show-2xl {
  display: none !important;
}

@media (min-width: 90em) {
  .show-2xl {
    display: block !important;
  }
}

.spacer {
  flex-grow: 1;
}

.stack > * {
  --stack: var(--space-2);
  margin-block: 0;
}

.stack > * + * {
  margin-block-start: var(--stack) !important;
}

.stack[data-stack="4"] > * {
  --stack: var(--space-4);
}

.stack[data-stack="6"] > * {
  --stack: var(--space-6);
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.visually-hidden {
  white-space: nowrap;
  position: absolute;
  inline-size: 1px !important;
  block-size: 1px !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

/*# sourceMappingURL=app.css.map */
