@use "./../tools/breakpoints" as *;

.article__content {
  max-inline-size: 70ch;
  margin-inline-end: auto;
  margin-block: var(--space-6);
}

.article__title {
  margin: 0;
  margin-block-end: var(--space-6);
  font-size: var(--font-size-4xl);
}

.article__content {
  p {
    margin-block-end: var(--space-4);
    line-height: 1.45;

    &:last-child {
      margin-block-end: 0;
    }
  }

  ul,
  ol {
    margin-block: var(--space-4);
    padding-inline-start: var(--space-4);
  }

  li > ul,
  li > ol {
    margin-block: var(--space-1);
  }

  h1 {
    margin-block-start: var(--space-6);
    margin-block-end: var(--space-1);
    font-size: var(--font-size-2xl);
  }

  h2 {
    margin-block-start: var(--space-6);
    margin-block-end: var(--space-1);
    font-size: var(--font-size-xl);
  }

  h1 + h2,
  h2 + h3,
  h3 + h4,
  h4 + h5,
  h5 + h6 {
    margin-block-start: var(--space-1);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    scroll-margin-block-start: 70px;

    @include media-breakpoint-up("md") {
      scroll-margin-block-start: 20px;
    }
  }

  a {
    color: var(--color-primary-text);
  }

  table {
    inline-size: 100%;
    margin-block: var(--space-5);
    border-block-start: 1px solid var(--color-neutral-border);
    border-collapse: collapse;
  }

  table td,
  table th {
    border-bottom: 1px solid var(--color-neutral-border);
    padding-inline: var(--space-1);
  }

  table td {
    padding-block: var(--space-3);
  }

  table th {
    padding-block: var(--space-2);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    letter-spacing: 0.01em;
  }

  blockquote {
    border-inline-start: 3px solid var(--color-neutral-line);
    padding-inline-start: var(--space-4);

    p {
      color: var(--color-neutral-text);
      font-style: italic;
    }
  }

  pre,
  code {
    font-family: var(--font-family-mono);
    line-height: 1.5;
  }

  pre {
    font-size: 14px;
    line-height: 1.375;
    direction: ltr;
    text-align: start;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    tab-size: 2;
    hyphens: none;
    margin: 0;
    margin-block-end: var(--space-4) !important;
    padding: 1em;
    border-radius: var(--rounded-lg);
    background-color: var(--color-neutral-bgSubtle);
    overflow: auto;
  }

  code {
    font-size: 0.9em;
    padding: 0.2em 0.3em;
    border-radius: var(--rounded-md);
    background-color: var(--color-neutral-bg);
  }

  pre code {
    font-size: inherit;
    padding: 0;
    word-break: break-all;
    background-color: transparent !important;
  }

  .footnotes-sep {
    margin-block: var(--space-6);
    border-style: solid;
    border-color: var(--color-neutral-line);
  }

  /* Direct link */
  .direct-link {
    outline-offset: 4px;
    text-decoration: none;
    margin-inline-start: 0.1em;
    color: transparent;
    transition: color 300ms ease;
  }

  *:hover > .direct-link,
  .direct-link:focus {
    color: var(--color-neutral-text);
  }
}
